import React, {useState} from 'react';
import {SmileOutlined} from '@ant-design/icons';
import {
    Form,
    Input,
    Button,
    Alert,
    Typography,
    Collapse,
    notification,
    Checkbox,
    Radio,
    Space,
} from 'antd';
import axios from "axios";
import {API_URI} from "../../constant/Config";

const {Panel} = Collapse;
const {TextArea} = Input;
const {Title, Text} = Typography;


class OrderFlightNoNecessary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataCmd: this.props.dataCmd,
            filghtType: this.props.filghtType,
            libTexte: false,
            datasLoad: false,
            checkboxVolInfo: false,
            colSqlCheckbox: false,
            openColspace: [],
        }
    }

    async componentDidMount() {


        if (this.state.filghtType === 0) {
            // vol aller
            this.state.libTexte = {
                'header': 'J\'arrive dans le pays avant la mission et n\'ai pas besoin d\'accueil à l\'aéroport',
                'infos': 'IMPORTANT – Veuillez impérativement respecter les jours et heures d\'arrivée et de départ indiqués dans les documents disponibles sur votre espace membre.',
                'motifData': this.props.dataCmd.volsInfos.getVolAllerNonNecessaireMotif,
                'sousinfos': 'En cas de doute ou de question, n\'hésitez pas à nous contacter avant de procéder à l\'achat de votre billet.',
                'motifTtre': 'Si vous ne pouvez pas respecter les dates et horaires de départ prévus pour la mission, et que votre programme inclut un retour vers l’aéroport (merci de vérifier les conditions de votre projet), veuillez choisir l’une des options suivantes :',
                'motif1': 'Arrivée anticipée sans accueil : Je serai dans le pays avant le début de la mission et n\'ai pas besoin d\'accueil à l\'aéroport. Je prendrai contact avec l\'équipe locale pour organiser mon arrivée de manière autonome',
                'motif2': 'Arrivée anticipée avec accueil exceptionnel : Je serai dans le pays avant le début de la mission et souhaiterais bénéficier d\'un accueil à l\'aéroport, ainsi qu\'ajouter des nuitées supplémentaires (sous réserve de disponibilité, des frais supplémentaires peuvent être appliqués)',
                'motif3': 'Arrivée retardée avec accueil différé : J\'arrive après la date initialement prévue et souhaite solliciter un accueil différé à l\'aéroport (sous réserve de disponibilité, des frais supplémentaires peuvent être appliqués)',
                'motifSelect': this.props.dataCmd.volsInfos.getVolAllerNonNecessaireMotifSelect,
            }
            await this.setState({colSqlCheckbox: this.props.dataCmd.volsInfos.getVolAllerNonNecessaire});


        } else if (this.state.filghtType === 1) {
            //vol retour
            this.state.libTexte = {
                'header': 'Je reste dans le pays après la mission et n\'ai pas besoin d\'être raccompagné à l\'aéroport',
                'infos': 'IMPORTANT – Veuillez impérativement respecter les jours et heures d\'arrivée et de départ indiqués dans les documents disponibles sur votre espace membre.',
                'motifData': this.props.dataCmd.volsInfos.getVolRetourNonNecessaireMotif,
                'sousinfos': 'En cas de doute ou de question, n\'hésitez pas à nous contacter avant de procéder à l\'achat de votre billet.',
                'motifTtre': 'Si vous ne pouvez pas respecter les dates et horaires d’arrivée prévus pour la mission, et que votre programme inclut un accueil à ’aéroport (merci de vérifier les conditions de votre projet), veuillez choisir l’une des options suivantes :',
                'motif1': 'Départ anticipé avec accompagnement exceptionnel : Je quitterai le pays avant la fin de la mission et souhaiterais bénéficier d\'un accompagnement à l\'aéroport  (sous réserve de disponibilité, des frais supplémentaires peuvent être appliqués)',
                'motif2': 'Départ retardé avec accompagnement différé : Je quitterai le pays après la date initialement prévue et souhaite solliciter un accompagnement différé à l\'aéroport et ajouter des nuitées supplémentaires dans l’hébergement  (sous réserve de disponibilité, des frais supplémentaires peuvent être appliqués)',
                'motif3': 'Séjour prolongé pour voyage personnel : Je resterai dans le pays après la fin de la mission pour voyager et n\'aurai pas besoin d\'être raccompagné à l\'aéroport',
                'motifSelect': this.props.dataCmd.volsInfos.getVolRetourNonNecessaireMotifSelect,
            }
            await this.setState({colSqlCheckbox: this.props.dataCmd.volsInfos.getVolRetourNonNecessaire});
        }




        await console.log(this.state.colSqlCheckbox)

        if (this.state.colSqlCheckbox == '1') {
            await this.setState({
                checkboxVolInfo: true,
                openColspace : ['1'],
            })
        }

        await this.setState({datasLoad: true})
    }


    sendForm = async values => {
        var token = localStorage.getItem('membre_token');
        await axios.post(API_URI + '/api_member/flightmotif', {
            id: this.state.dataCmd.data.id,
            motif: values.motif,
            motifSelect: values.motifSelect,
            action: 'update',
            typeVol: this.state.filghtType,
        }, {
            headers: {
                'Authorization': token
            }
        });

        notification.open({
            message: 'Motif mis à jour',
            description:
                "Nous vous confirmons la mise à jour du motif.",
            icon: <SmileOutlined style={{color: '#108ee9'}}/>,
        });
    }

    sendFormCancel = async () => {
        var token = localStorage.getItem('membre_token');
        await axios.post(API_URI + '/api_member/flightmotif', {
            id: this.state.dataCmd.data.id,
            action: 'cancel',
            typeVol: this.state.filghtType,
        }, {
            headers: {
                'Authorization': token
            }
        });

        notification.open({
            message: 'Motif annulé',
            description:
                "Nous vous confirmons l'annulation du modif.",
            icon: <SmileOutlined style={{color: '#108ee9'}}/>,
        });
    }

    getFormFull = () => {
        return <>
            <Form
                labelCol={{
                    span: 18,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="vertical"
                initialValues={{
                    motifSelect: this.state.libTexte.motifSelect, // Définir la valeur initiale pour le champ radio
                    motif: this.state.libTexte.motifData,
                }}
                onFinish={this.sendForm}
            >

                <Form.Item
                    label={this.state.libTexte.motifTtre}
                    name="motifSelect"
                    rules={[{ required: true, message: 'Veuillez sélectionner un motif.' }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="1"> {this.state.libTexte.motif1} </Radio>
                            <Radio value="2"> {this.state.libTexte.motif2} </Radio>
                            <Radio value="3"> {this.state.libTexte.motif3} </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Merci de nous donner des détails supplémentaires"
                    name={['motif']}

                >
                    <TextArea rows={4}/>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" type="primary">Valider</Button> <Button danger
                                                                                      onClick={this.sendFormCancel}>Annuler
                    mon
                    motif </Button>
                </Form.Item>
            </Form>
        </>
    }

    onChangeInfosVolRetourAller = async (key) => {
        if (key.length == 0) {
            this.setState({
                checkboxVolInfo: false,
            });
            await this.sendFormCancel();
        } else if (key.length == 1) {
            this.setState({
                checkboxVolInfo: true,
            })
        }
    }

    render() {

        return (
            <>
                <Title level={3}>Informations supplémentaires</Title>
                <Alert
                    message={this.state.libTexte.infos}
                    description={this.state.libTexte.sousinfos}
                    type="info"
                    showIcon
                />
                <br/>
                <Collapse defaultActiveKey={this.state.openColspace} onChange={this.onChangeInfosVolRetourAller}>
                    <Panel header={<div><Checkbox
                        checked={this.state.checkboxVolInfo}></Checkbox> {this.state.libTexte.header}</div>}
                           key="1">
                        <div>

                            {this.state.datasLoad ? this.getFormFull() : ''}

                        </div>
                    </Panel>
                </Collapse>
            </>
        )
    }
}

export default OrderFlightNoNecessary;